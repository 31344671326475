// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-igorko-redirect-js": () => import("/Users/lukapopovic/dev/narmocode_website/.cache/@igorko/redirect.js" /* webpackChunkName: "component---cache-igorko-redirect-js" */),
  "component---src-templates-imprint-template-js": () => import("/Users/lukapopovic/dev/narmocode_website/src/templates/imprintTemplate.js" /* webpackChunkName: "component---src-templates-imprint-template-js" */),
  "component---src-templates-disclaimer-template-js": () => import("/Users/lukapopovic/dev/narmocode_website/src/templates/disclaimerTemplate.js" /* webpackChunkName: "component---src-templates-disclaimer-template-js" */),
  "component---src-templates-privacy-template-js": () => import("/Users/lukapopovic/dev/narmocode_website/src/templates/privacyTemplate.js" /* webpackChunkName: "component---src-templates-privacy-template-js" */),
  "component---src-pages-index-js": () => import("/Users/lukapopovic/dev/narmocode_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/lukapopovic/dev/narmocode_website/.cache/data.json")

